export const userConstants = {
	Login: {
		REQUEST : "USERS_LOGIN_REQUEST",
		SUCCESS : "USERS_LOGIN_SUCCESS",
		FAILURE : "USERS_LOGIN_FAILURE"
	},
	BelpochtaLogin: {
		REQUEST : "BELPOCHTA_LOGIN_REQUEST",
		SUCCESS : "BELPOCHTA_LOGIN_SUCCESS",
		FAILURE : "BELPOCHTA_LOGIN_FAILURE"
	},
	ClearLoginData : "CLEAR_LOGIN_DATA_REQUEST",
	SignLogin      : {
		REQUEST : "USERS_SIGNLOGIN_REQUEST",
		SUCCESS : "USERS_SIGNLOGIN_SUCCESS",
		FAILURE : "USERS_SIGNLOGIN_FAILURE"
	},
	ChangeSign: {
		REQUEST : "USERS_CHANGESIGN_REQUEST",
		SUCCESS : "USERS_CHANGESIGN_SUCCESS",
		FAILURE : "USERS_CHANGESIGN_FAILURE"
	},
	Logout: {
		REQUEST : "USERS_LOGOUT_REQUEST",
		SUCCESS : "USERS_LOGOUT_SUCCESS",
		FAILURE : "USERS_LOGOUT_FAILURE"
	},
	RefreshUserData: {
		REQUEST : "REFRESH_USER_DATA_REQUEST",
		SUCCESS : "REFRESH_USER_DATA_SUCCESS",
		FAILURE : "REFRESH_USER_DATA_FAILURE"
	},
	Unblock: {
		REQUEST : "UNBLOCK_REQUEST",
		SUCCESS : "UNBLOCK_SUCCESS",
		FAILURE : "UNBLOCK_FAILURE"
	},
	ConfirmPhone: {
		REQUEST : "CONFIRM_PHONE_REQUEST",
		SUCCESS : "CONFIRM_PHONE_SUCCESS",
		FAILURE : "CONFIRM_PHONE_FAILURE"
	},
	Register: {
		REQUEST : "REGISTER_REQUEST",
		SUCCESS : "REGISTER_SUCCESS",
		FAILURE : "REGISTER_FAILURE"
	},
	GetUserTypes: {
		REQUEST : "GETUSERTYPES_REQUEST",
		SUCCESS : "GETUSERTYPES_SUCCESS",
		FAILURE : "GETUSERTYPES_FAILURE"
	},
	ChangeProfileData: {
		REQUEST : "CHANGEPROFILEDATA_REQUEST",
		SUCCESS : "CHANGEPROFILEDATA_SUCCESS",
		FAILURE : "CHANGEPROFILEDATA_FAILURE"
	},
	RegisterByAdmin: {
		REQUEST : "REGISTER_BY_ADMIN_REQUEST",
		SUCCESS : "REGISTER_BY_ADMIN_SUCCESS",
		FAILURE : "REGISTER_BY_ADMIN_FAILURE",
		CLEAR   : "REGISTER_BY_ADMIN_CLEAR"
	},
	RegisterByExternalAdmin: {
		REQUEST : "REGISTER_BY_EXTERNAL_ADMIN_REQUEST",
		SUCCESS : "REGISTER_BY_EXTERNAL_ADMIN_SUCCESS",
		FAILURE : "REGISTER_BY_EXTERNAL_ADMIN_FAILURE"
	},
	GetRoles: {
		REQUEST : "GET_ROLES_REQUEST",
		SUCCESS : "GET_ROLES_SUCCESS",
		FAILURE : "GET_ROLES_FAILURE",
		CLEAR   : "GET_ROLES_CLEAR"
	},
	GetIFULLoginParameters: {
		REQUEST : "GET_IFUL_LOGIN_PARAMETERS_REQUEST",
		SUCCESS : "GET_IFUL_LOGIN_PARAMETERS_SUCCESS",
		FAILURE : "GET_IFUL_LOGIN_PARAMETERS_FAILURE",
		CLEAR   : "GET_IFUL_LOGIN_PARAMETERS_CLEAR"
	},
	IFULLogin: {
		REQUEST : "GET_IFUL_LOGIN_REQUEST",
		SUCCESS : "GET_IFUL_LOGIN_SUCCESS",
		FAILURE : "GET_IFUL_LOGIN_FAILURE"
	},
	GetIFULBauthParameters: {
		REQUEST : "GET_IFUL_BAUTH_PARAMETERS_REQUEST",
		SUCCESS : "GET_IFUL_BAUTH_PARAMETERS_SUCCESS",
		FAILURE : "GET_IFUL_BAUTH_PARAMETERS_FAILURE",
		CLEAR   : "GET_IFUL_BAUTH_PARAMETERS_CLEAR"
	},
	BauthInit: {
		REQUEST : "GET_BAUTHINIT_REQUEST",
		SUCCESS : "GET_BAUTHINIT_SUCCESS",
		FAILURE : "GET_BAUTHINIT_FAILURE"
	},
	BauthProcess: {
		REQUEST : "GET_BAUTHPROCESS_REQUEST",
		SUCCESS : "GET_BAUTHPROCESS_SUCCESS",
		FAILURE : "GET_BAUTHPROCESS_FAILURE"
	},
	DgInit: {
		REQUEST : "GET_DGINIT_REQUEST",
		SUCCESS : "GET_DGINIT_SUCCESS",
		FAILURE : "GET_DGINIT_FAILURE"
	},
	DgRead: {
		REQUEST : "GET_DGREAD_REQUEST",
		SUCCESS : "GET_DGREAD_SUCCESS",
		FAILURE : "GET_DGREAD_FAILURE"
	},
	DgRequest: {
		REQUEST : "GET_DGREQUEST_REQUEST",
		SUCCESS : "GET_DGREQUEST_SUCCESS",
		FAILURE : "GET_DGREQUEST_FAILURE"
	},
	CheckSystemEnvironment: {
		REQUEST : "CHECK_SYSTEM_ENVIRONMENT_REQUEST",
		SUCCESS : "CHECK_SYSTEM_ENVIRONMENT_SUCCESS",
		FAILURE : "CHECK_SYSTEM_ENVIRONMENT_FAILURE"
	},
	AttachIdNumberFromIFUL: {
		REQUEST : "ATTACH_ID_NUMBER_FROM_ID_CARD_REQUEST",
		SUCCESS : "ATTACH_ID_NUMBER_FROM_ID_CARD_SUCCESS",
		FAILURE : "ATTACH_ID_NUMBER_FROM_ID_CARD_FAILURE"
	},
	AttachIdNumberFromProfile: {
		REQUEST : "ATTACH_ID_NUMBER_FROM_PROFILE_REQUEST",
		SUCCESS : "ATTACH_ID_NUMBER_FROM_PROFILE_SUCCESS",
		FAILURE : "ATTACH_ID_NUMBER_FROM_PROFILE_FAILURE"
	},
	DetachIdNumber: {
		REQUEST : "DETACH_ID_NUMBER_FROM_PROFILE_REQUEST",
		SUCCESS : "DETACH_ID_NUMBER_FROM_PROFILE_SUCCESS",
		FAILURE : "DETACH_ID_NUMBER_FROM_PROFILE_FAILURE"
	},
	CheckIsUserNameUnique: {
		REQUEST : "CHECK_IS_USER_NAME_UNIQUE_REQUEST",
		SUCCESS : "CHECK_IS_USER_NAME_UNIQUE_SUCCESS",
		FAILURE : "CHECK_IS_USER_NAME_UNIQUE_FAILURE",
		CLEAR   : "CHECK_IS_USER_NAME_UNIQUE_CLEAR"
	}
}
