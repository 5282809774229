import { userConstants } from "../constants/";
import { defAction } from "../../helpers/defaultAction";
import agent from "../../api/agent";
import history from "../../helpers/history";

export const userActions = {
  login,
  belpochtaLogin,
  signlogin,
  changesign,
  logout,
  refreshUserData,
  unblock,
  register,
  getUserTypes,
  changeProfileData,
  confirmPhone,
  getRoles,
  clearRoles,
  registerByAdmin,
  registerByExternalAdmin,
  getIFULLoginParameters,
  getIFULBauthParameters,
  clearIFUL,
  clearBauth,
  IFULLogin,
  IFULLogout,
  BauthInit,
  BauthProcess,
  DgInit,
  DgRead,
  DgRequest,
  BelpochtaLogin,
  checkSystemEnvironment,
  clearRegisterByAdmin,
  attachIdNumberFromIFUL,
  attachIdNumberFromProfile,
  detachIdNumber,
  checkIsUserNameUnique,
  clearUserNameIsUnique
};

function clearRoles() {
  return (dispatch) =>
    dispatch({
      type: userConstants.GetRoles.CLEAR,
    });
}

function clearRegisterByAdmin() {
  return (dispatch) =>
    dispatch({
      type: userConstants.RegisterByAdmin.CLEAR,
    });
}

function clearIFUL() {
  return (dispatch) =>
    dispatch({
      type: userConstants.GetIFULLoginParameters.CLEAR,
    });
}

function clearBauth() {
  return (dispatch) =>
    dispatch({
      type: userConstants.GetIFULBauthParameters.CLEAR,
    });
}

function login(values) {
  const dispatchObj = {
    constants: userConstants.Login,
    service: {
      func: agent.User.login,
      params: values,
    },
    sucFunction: () => {
      history.push("/account");
    },
  };
  return defAction(dispatchObj);
}

function belpochtaLogin(values) {
  const dispatchObj = {
    constants: userConstants.BelpochtaLogin,
    service: {
      func: agent.User.belpochtaLogin,
      params: values,
    },
    sucFunction: () => history.push("/account"),
  };
  return defAction(dispatchObj);
}

function signlogin(IdentificationNumber) {
  const dispatchObj = {
    constants: userConstants.SignLogin,
    service: {
      func: agent.User.signlogin,
      params: {
        IdentificationNumber,
      },
    },
    sucFunction: () => history.push("/account"),
  };
  return defAction(dispatchObj);
}

function changesign(values) {
  const dispatchObj = {
    constants: userConstants.ChangeSign,
    service: {
      func: agent.User.changeSign,
      params: values,
    },
  };
  return defAction(dispatchObj);
}

function logout(sessionLogout = false) {
  const jsonUser = window.localStorage.getItem("user");
  const idToken = window.localStorage.getItem("idToken");
  const sessionId = JSON.parse(jsonUser).sessionId;
  const dispatchObj = {
    constants: userConstants.Logout,
    sucFunction: (url) => {
      history.push("/");

      window.localStorage.removeItem("idToken");
      if (
        !(
          url === false ||
          url === true ||
          url === undefined ||
          url === null ||
          url === ""
        )
      )
        window.location.href = url;
    },
    service: {
      func: agent.User.logout,
      params: {
        idToken: idToken,
        sessionId: sessionId,
        sessionLogout: sessionLogout
      },
    },
  };
  return defAction(dispatchObj);
}

function refreshUserData() {
  const dispatchObj = {
    constants: userConstants.RefreshUserData,
    service: {
      func: agent.User.refreshUserData,
    },
  };
  return defAction(dispatchObj);
}

function unblock(values) {
  const dispatchObj = {
    constants: userConstants.Unblock,
    service: {
      func: agent.User.unblock,
      params: values,
    },
  };
  return defAction(dispatchObj);
}

function confirmPhone(values) {
  const dispatchObj = {
    constants: userConstants.ConfirmPhone,
    service: {
      func: agent.User.confirmPhone,
      params: values,
    },
    sucFunction: () => history.push("/login"),
  };
  return defAction(dispatchObj);
}

function register(values) {
  const dispatchObj = {
    constants: userConstants.Register,
    service: {
      func: getAgentUserFunction(values.userType.name, "Register"),
      params: values,
    },
    sucFunction: () => history.push("/login"),
  };
  return defAction(dispatchObj);
}

function registerByAdmin(values) {
  const dispatchObj = {
    constants: userConstants.RegisterByAdmin,
    service: {
      func: getAgentUserFunction(values.userType.name, "Register"),
      params: values,
    },
    sucFunction: () => (values.isAdmin ? history.push("/users") : null),
  };
  return defAction(dispatchObj);
}

function registerByExternalAdmin(values) {
  const dispatchObj = {
    constants: userConstants.RegisterByExternalAdmin,
    service: {
      func: getAgentUserFunction(values.userType.name, "Register"),
      params: values,
    },
    sucFunction: () => history.push("/orgManagement"),
  };
  return defAction(dispatchObj);
}

function getAgentUserFunction(userTypeName, type) {
  return agent.User[userTypeName + type];
}

function getUserTypes(values) {
  const dispatchObj = {
    constants: userConstants.GetUserTypes,
    service: {
      func: agent.User.getUserTypes,
      params: values,
    },
  };
  return defAction(dispatchObj);
}

function changeProfileData(data) {
  const dispatchObj = {
    constants: userConstants.ChangeProfileData,
    service: {
      func: getAgentUserFunction(data.userType.name, "Change"),
      params: data,
    },
  };
  return defAction(dispatchObj);
}

function getRoles(values) {
  const dispatchObj = {
    constants: userConstants.GetRoles,
    service: {
      func: agent.User.getRoles,
      params: values,
    },
  };
  return defAction(dispatchObj);
}

function getIFULLoginParameters() {
  const dispatchObj = {
    constants: userConstants.GetIFULLoginParameters,
    service: {
      func: agent.User.getIFULLoginParameters,
    },
  };
  return defAction(dispatchObj);
}

function IFULLogin(values) {
  const dispatchObj = {
    constants: userConstants.IFULLogin,
    service: {
      func: agent.User.IFULLogin,
      params: values,
    },
  };
  return defAction(dispatchObj);
}

function IFULLogout(values) {
  const dispatchObj = {
    constants: userConstants.IFULLogin,
    service: {
      func: agent.User.IFULLogin,
      params: values,
    },
  };
  return defAction(dispatchObj);
}

function getIFULBauthParameters() {
  const dispatchObj = {
    constants: userConstants.GetIFULBauthParameters,
    service: {
      func: agent.User.getIFULBauthParameters,
    },
  };
  return defAction(dispatchObj);
}

function BauthInit(values) {
  const dispatchObj = {
    constants: userConstants.BauthInit,
    service: {
      func: agent.User.BauthInit,
      params: values,
    },
  };
  return defAction(dispatchObj);
}

function BauthProcess(values) {
  const dispatchObj = {
    constants: userConstants.BauthProcess,
    service: {
      func: agent.User.BauthProcess,
      params: values,
    },
  };
  return defAction(dispatchObj);
}

function DgInit(values) {
  const dispatchObj = {
    constants: userConstants.DgInit,
    service: {
      func: agent.User.DgInit,
      params: values,
    },
  };
  return defAction(dispatchObj);
}
function DgRead(values) {
  const dispatchObj = {
    constants: userConstants.DgRead,
    service: {
      func: agent.User.DgRead,
      params: values,
    },
  };
  return defAction(dispatchObj);
}
function DgRequest(values) {
  const dispatchObj = {
    constants: userConstants.DgRequest,
    service: {
      func: agent.User.DgRequest,
      params: values,
    },
  };
  return defAction(dispatchObj);
}

function BelpochtaLogin(values) {
  const dispatchObj = {
    constants: userConstants.BelpochtaLogin,
    service: {
      func: agent.User.BelpochtaLogin,
      params: values,
    },
  };
  return defAction(dispatchObj);
}

function checkSystemEnvironment() {
  const dispatchObj = {
    constants: userConstants.CheckSystemEnvironment,
    service: {
      func: agent.User.checkSystemEnvironment,
    },
  };

  return defAction(dispatchObj);
}

function attachIdNumberFromIFUL(params) {
  const dispatchObj = {
    constants: userConstants.AttachIdNumberFromIFUL,
    service: {
      func: agent.User.attachIdNumberFromIFUL,
      params,
    },
  };

  return defAction(dispatchObj);
}

function attachIdNumberFromProfile(params) {
  const dispatchObj = {
    constants: userConstants.AttachIdNumberFromProfile,
    service: {
      func: agent.User.attachIdNumberFromProfile,
      params,
    },
  };

  return defAction(dispatchObj);
}

function detachIdNumber() {
  const dispatchObj = {
    constants: userConstants.DetachIdNumber,
    service: {
      func: agent.User.detachIdNumber,
    },
  };

  return defAction(dispatchObj);
}

function checkIsUserNameUnique(params){
  const dispatchObj = {
    constants: userConstants.CheckIsUserNameUnique,
    service: {
      func: agent.User.checkIsUserNameUnique,
      params
    },
  };

  return defAction(dispatchObj);
}

function clearUserNameIsUnique(){
  return (dispatch) =>
    dispatch({
      type: userConstants.CheckIsUserNameUnique.CLEAR,
    });
}